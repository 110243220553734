import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-polyglot';
import { graphql } from 'gatsby';

import withSetLocale from '../../components/i18n/withSetLocale';

import Layout from '../../components/layout';
import LegalPage from '../../components/LegalPage';

const Legal = ({ data, pageContext: { locale, messages } }) => (
    <Layout>
        <I18n locale={locale} messages={messages}>
            <LegalPage locale={locale} data={data} />
        </I18n>
    </Layout>
);

Legal.propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired,
};

export default withSetLocale()(Legal);

export const query = graphql`
    query LegalQuery {
        headerImage: file(relativePath: { eq: "legal.jpg" }) {
            publicURL
            absolutePath
            name
            childImageSharp {
                fluid(maxWidth: 1280, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
