import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { translate } from 'react-polyglot';
import compose from 'recompose/compose';
import { Helmet } from 'react-helmet';
import mapProps from 'recompose/mapProps';

import MaxWidthContainer from './MaxWidthContainer';
import TopBar from './TopBar';
import AppBar from './AppBar';
import HeaderImage from './HeaderImage';
import Footer from './Footer';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 300;
    margin-top: 3rem;
    margin-bottom: 2rem;
    text-align: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.headlinesColor};

    @media (max-width: 600px) {
        font-size: 1.5rem;
        line-height: 2rem;
    }
`;

const SubTitle = styled.h2`
    color: ${({ theme }) => theme.text};
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 3rem;
    text-align: left;

    @media (min-width: 900px) {
        &:first-child {
            margin-top: 0;
        }
    }
`;

const PresentationContainer = styled.div`
    padding: 1.875rem;
    background-color: white;
    margin: 0 auto 2rem auto;
    border-bottom: solid 1px ${({ theme }) => theme.shadowColor};
    @media (max-width: 600px) {
        width: auto;
        height: auto;
        padding: 1em;
    }
`;

const LegalInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.625rem;
`;

const LegalInfoLabel = styled.p`
    font-weight: bold;
    margin: 0;
`;

const LegalInfoValue = styled.p`
    margin: 0;
`;

const LegalInfo = ({ label, value }) => (
    <LegalInfoContainer>
        <LegalInfoLabel>{label}</LegalInfoLabel>
        <LegalInfoValue>{value}</LegalInfoValue>
    </LegalInfoContainer>
);

LegalInfo.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.node.isRequired,
};

const TextContainer = styled.div`
    margin-top: 0;
    padding: 0.625rem;
    > p:first-child {
        margin-top: 0;
    }
`;

const VerticalSplit = styled.div`
    display: flex;
    flex-direction: row;

    > div:first-child > :first-child {
        margin-top: 0;
    }

    > div:nth-child(2) {
        width: 450px;
        margin-left: 80px;
    }

    @media (max-width: 900px) {
        justify-content: center;
        flex-wrap: wrap;
        > div {
            width: 100% !important;
        }
        > div:nth-child(2) {
            margin-left: 0;
        }
    }
`;

const Legal = ({ locale, t, headerImage }) => (
    <Container>
        <Helmet title={t('seo.defaultTitle')}>
            <html lang={locale} />
            <meta name="description" content={t('seo.defaultDescription')} />
        </Helmet>
        <TopBar white />
        <AppBar />
        <HeaderImage image={headerImage} />
        <MaxWidthContainer role="main" width={1024}>
            <Title
                dangerouslySetInnerHTML={{
                    __html: t('legal.title'),
                }}
            />
            <PresentationContainer>
                <VerticalSplit>
                    <div>
                        <SubTitle>{t('legal.editor.title')}</SubTitle>
                        <LegalInfo
                            label={t('legal.editor.company')}
                            value={'Marmelab'}
                        />
                        <LegalInfo
                            label={t('legal.editor.capital')}
                            value={'50 000€'}
                        />
                        <LegalInfo
                            label={t('legal.editor.registrationNumber')}
                            value={'791 515 612 RCS Nancy'}
                        />
                        <LegalInfo
                            label={t('legal.editor.VATNumber')}
                            value={'FR40791515612'}
                        />
                        <LegalInfo
                            label={t('legal.editor.address')}
                            value={'4 rue Girardet, 54000 Nancy, France'}
                        />
                        <LegalInfo
                            label={t('legal.editor.emailAddress')}
                            value={
                                <a href="mailto:contact@marmelab.com">
                                    contact@marmelab.com
                                </a>
                            }
                        />
                        <LegalInfo
                            label={t('legal.editor.publishingDirector')}
                            value={'François Zaninotto'}
                        />
                        <SubTitle>{t('legal.host.title')}</SubTitle>
                        <LegalInfo
                            label={t('legal.host.provider')}
                            value={'GitHub Pages'}
                        />
                        <LegalInfo
                            label={t('legal.host.address')}
                            value={
                                '88 Colin P Kelly Jr St, San Francisco, CA 94107, United States'
                            }
                        />
                        <LegalInfo
                            label={t('legal.host.phoneNumber')}
                            value={'+1 (877) 448-4820'}
                        />
                    </div>
                    <div>
                        <SubTitle>{t('legal.privacy.title')}</SubTitle>
                        <TextContainer
                            dangerouslySetInnerHTML={{
                                __html: t('legal.privacy.content'),
                            }}
                        />
                    </div>
                </VerticalSplit>
            </PresentationContainer>
        </MaxWidthContainer>
        <Footer />
    </Container>
);

Legal.propTypes = {
    locale: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    headerImage: PropTypes.object,
};

export default compose(
    mapProps(({ locale, t, data }) => {
        return {
            locale,
            t,
            headerImage: data.headerImage,
        };
    }),
    translate(),
)(Legal);
